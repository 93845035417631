<template>
    <div>
        <img class="example-headImg" src="../assets/image/selected/08-1banner.png"/>
        <div class="head-explain">
<!--            <img class="head-img-logo" src="../assets/image/selected/08-1iSales.png"/>-->
            <div class="head-explain-title">智慧医防协同</div>
            <div class="head-explain-content">构建电子监控档案数据库、电子病历数据库、全员人口个案数据库。</div>
            <div class="head-explain-content" style="margin-top:-3rem">成为客户首选的科技创新伙伴</div>
        </div>
        <div class="container-1">
            <div class="saled-box1">
                <div class="title1">
                  构建以个人健康档案为核心，以医疗资源共享为基础的区域卫生信息资源中心平台，实现从基层的社区直到各级医l之间的诊疗活动的协同工作，为病人提供合理的、及时的、准确的就诊服务<br/>
                  真正做到以病人为中心，对病人进行调度、跟踪与服务。依托于电子健康档案和电子病历，支撑公共卫生、医疗服务、医疗保障、药品管理、计划生育、综合管理等业务应用
                </div>
            </div>
        </div>
        <div class="container-2" style="height:90rem">
            <div class="saled-box2">
                <div class="container">
                    <div class="card">
                        <img src="../assets/image/selected/08-1全面覆盖.png"/>
                        <div class="title">全面覆盖<br/>
                        全民健康信息协同</div>
                    </div>
                    <div class="card">
                        <img src="../assets/image/selected/08-1灵活应对.png"/>
                        <div class="title">灵活应对<br/> 区域医疗服务协同</div>
                    </div>
                    <div class="card">
                        <img src="../assets/image/selected/08-1标准化.png"/>
                        <div class="title">标准化<br/>
                          紧密型医共体协同</div>
                    </div>
                </div>
                <img class="saled-box2-img" src="../assets/image/selected/08-1中图.png"/>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.saled-box1{
    width: 139.7rem;
    height: 8.8rem;
    margin: auto;
    margin-top: 4.4rem;
    margin-bottom: 2rem;
    .title1{
        width: 139.7rem;
        height: 8.8rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #333333;
        line-height: 3rem;
        margin-top: 2rem;

    }
}
.saled-box2{
    width: 86rem;
    margin: auto;
    .container{
        width: 86rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .card{
            width: 26rem;
            display: flex;
            margin-top: 5rem;
            margin-bottom: 5.6rem;
            img{
                width: 7rem;
                height: 7rem;
                float: left;
            }
            .title{
                margin-left: 2.1rem;
                width: 18rem;
                font-size: 1.4rem;
                font-family: 'CN_Normal';
                text-align: left;
                margin-top: 1.5rem;
                font-weight: 400;
                color: #666666;
                line-height: 2rem;
            }
        }
    }
    .saled-box2-img{
        width: 84.1rem;
        height: 61.6rem;
    }
}
</style>
